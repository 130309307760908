import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useOutsideAlerter,
  handleSidebarDrawerInit,
  sideBarList,
} from "../../helper/utils";
import { getProfile } from "../../redux/slices/getProfileSlice";

import { deleteCookies } from "../../helper/utils";

import Left from "../../assets/images/svgs/left.svg";
import Right from "../../assets/images/svgs/right.svg";
import User from "../../assets/images/user.jpg";

const HumburgerMenu = ({ menuOpen, setMenuOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const {
    loading,
    error,
    data: loggedInUser,
  } = useSelector((state) => state.getProfile);

  const [currentPage, setCurrentPage] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [hoverProfile, setHoverProfile] = useState(false);

  const [open, setOpen] = useState(menuOpen);
  const [openLogout, setOpenLogout] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;

  useOutsideAlerter(wrapperRef, open, setOpen);

  useEffect(() => {
    // getProfileData()
   
  }, [loggedInUser]);
  useEffect(() => {
  
    if (width <= 990) {
      setMenuOpen(!open);
    } else {
      setMenuOpen(open);
    }
  }, [open]);

  const handleLogout = () => {
   
    deleteCookies("token");
    deleteCookies("role");
    setOpen(false);
    setOpenLogout(true);
    navigate("/");
  };

  const handledraweropen = (event) => {
    console.log(66111, open)
    event.preventDefault();
    setOpen(!open);
    handleSidebarDrawerInit();
    return true
  };

  const handleSidebarClick = (e) => {
  

    navigate(`/${e.url}`);
    setCurrentPage(e.label);
    return true
  };
  const getProfileData = () => {
    dispatch(getProfile())
      .unwrap()
      .then((res) => {
        console.log(64, res);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  };

  return (
    <div id="wrapper" className={`wrapper ${isToggled ? "" : "toggled"}`}>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand cursor-pointer" onClick={() => {
          navigate("/");
        }}>
          <h2>KENO</h2>
        </div>
        <ul className="sidebar-nav">
          {sideBarList?.map((item, index) => (
            <li
              className={
                item.label == currentPage ? `active` : `` + `cursor-pointer`
              }
              key={index}
              onClick={() => {
                handleSidebarClick(item);
              }}
            >
              <div>
                <span className="svg-icon svg-icon-2 ">{item.img}</span>

                <span className="menu-title ms-3 fs-14">{item.label}</span>
              </div>
            </li>
          ))}
        </ul>
      </aside>

      <div id="navbar-wrapper">
        <nav className="navbar navbar-inverse">
          <div className="container-fluid">
            <div
              className="navbar-header"
              onClick={(e) => {
                // e.preventDefault();
                setIsToggled((prevState) => !prevState);
                handledraweropen(e);
              }}
              
            >
              <span className="svg-icon svg-icon-2 sidebar-arrow text-center">
                <img
                  src={Left}
                  style={{
                    transform: open ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              </span>

              <a href="#" className="navbar-brand" id="sidebar-toggle">
                <i className="fa fa-bars"></i>
              </a>
            </div>
            <div className="app-navbar cursor-pointer flex-shrink-0">
              <div className="app-navbar-item ms-1 ms-md-3">
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  onMouseOver={() => {
                    console.log(143)
                    setHoverProfile(true);
                  }}
                  onMouseOut={() => {
                    setHoverProfile(false);
                  }}
                >
                  <img src={User} alt="" />
                </div>

                {hoverProfile && (
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-gray-800 py-2 py-sm-2 py-md-3 py-lg-4 fs-6"
                    onMouseOver={() => {
                      setHoverProfile(true);
                    }}
                    onMouseOut={() => {
                      setHoverProfile(false);
                    }}
                    onClick={(e) => {
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    <div className="menu-item px-2 px-sm-2 px-lg-3 cursor-none">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="d-flex flex-wrap">
                          <div className=" symbol symbol-30px symbol-md-40px">
                            <img src={User} alt="" />
                          </div>
                          <div className="fw-bold align-items-center mx-3">
                            <span className="fs-14">
                              {loggedInUser?.data?.name}{" "}
                            </span>

                            <div className="fw-semibold text-muted text-hover-primary fs-12">
                              {loggedInUser?.data?.email}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="separator my-2"></div>

                    <div
                      className="menu-item px-sm-2 px-md-2 px-lg-4 py-1 py-sm-1 py-md-2 py-lg-2 mx-2"
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <span className="menu-link  fs-14 px-2">My Profile</span>
                    </div>

                    <div className="separator my-1"></div>

                    <div className="menu-item px-sm-2 px-md-2 px-lg-4 py-1 py-sm-1 py-md-2 py-lg-2 mx-2">
                      <span
                        className="menu-link  fs-14 px-2"
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        Sign Out
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

HumburgerMenu.prototype = {
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
};

export default HumburgerMenu;
