import { configureStore } from "@reduxjs/toolkit";
import todoListSlice from "./slices/todoListSlice";
import loginSlice from "./slices/loginSlice";
import signUpSlice from "./slices/signupSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import getProfileSlice from "./slices/getProfileSlice";
import getClientListSlice from "./slices/clients/getClientListSlice";
import updateProfileSlice from "./slices/updateProfileSlice";
import createBetSlice from "./slices/game/createBetSlice";
import getPayoutTableSlice from "./slices/game/getPayoutTableSlice";
import gameHistorySlice from "./slices/game/gameHistorySlice";
import getRiskLevelSlice from "./slices/game/getRiskLevelSlice";

import userLoginSlice from "./slices/userLoginSlice";
import addBalanceSlice from "./slices/addBalanceSlice";

const store = configureStore({
  reducer: {
    todoList: todoListSlice,
    login: loginSlice,
    signup: signUpSlice,
    forgotPassword: forgotPasswordSlice,
    getProfile: getProfileSlice,
    getClients: getClientListSlice,
    updateProfile: updateProfileSlice,
    createBet: createBetSlice,
    getPayoutTable: getPayoutTableSlice,
    gameHistory: gameHistorySlice,
    getRiskLevel: getRiskLevelSlice,
    userLogin: userLoginSlice,
    addBalance: addBalanceSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        serializableCheck: false,
        ignoredPaths: ["payload.headers"],
      },
    }),
});

export default store;
