import React, { useState } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import moment from "moment";
import Button from "./button";
import User from "../assets/images/user.jpg";
import Edit from "../assets/images/svgs/edit.svg";
import Delete from "../assets/images/svgs/delete.svg";
import { getCookies } from "../helper/utils";
import Previous from "../assets/images/svgs/left.svg";
import Next from "../assets/images/svgs/right.svg";

const columns = [
  {
    name: "Select",
    type: "checkbox",
  },
  {
    name: "Author",
    type: "text-profile",
  },
  {
    name: "Company",
    type: "text",
  },
  {
    name: "Progress",
    type: "progress",
  },
  {
    name: "Actions",
    type: "button",
  },
];
const rows = [
  { Select: "", Author: "Riya", Company: "r", Progress: 40 },
  { Select: "", Author: "Redcliff", Company: "rd ", Progress: 90 },
];

const Table = ({
  loading,
  columns,
  rows,
  setRows,
  handleAction,
  skip,
  setSkip,
  total,
  handleClick,
  myBet,
  setMyBet,
}) => {
  const limit = 10;

  const totalPages = Math.ceil(total / limit);
  const maxVisibleButtons = 5; // Number of page buttons to show at once
  const [currentPage, setCurrentPage] = useState(skip);

  // Determine the range of visible page buttons
  const getVisiblePageNumbers = () => {
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisibleButtons / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  // const handlePageClick = (page) => {

  //   setSkip(Number(page));
  //   setCurrentPage(Number(page));
  // };

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNum) => {
    setSkip(Number(pageNum));
    setCurrentPage(pageNum);
  };

  return (
    <div className="card m-3 mb-xl-8">
      <div className="card-header border-0 pt-2 d-flex flex-wrap justify-content-sm-start justify-content-md-between ">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-14 mb-1">Game History</span>
        </h3>

        <div className=" py-2 px-3 w-50">
          <Button
            name={myBet ? "All Bet" : "My Bet"}
            type={"submit"}
            handleSubmit={() => {
              handleClick();
              setMyBet(!myBet);
            }}
          />
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold text-muted">
                {columns?.map((itm, index) => (
                  <th key={index} className="w-25px fs-14 cursor-pointer">
                    {itm.name}
                  </th>
                ))}
              </tr>
            </thead>

            {loading ? (
              <tbody>
                <tr>
                  <td>
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {rows?.map((itm, index) => (
                  <tr key={index}>
                    {columns?.map((i, index1) => (
                      <td key={index1}>
                        {i.type === "text" && (
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                href="#"
                                className="text-dark fw-bold text-hover-primary fs-12"
                              >
                                {itm[i.name]}
                              </a>
                            </div>
                          </div>
                        )}
                        {i.type === "text-profile" && (
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-45px me-2">
                              <img src={User} alt="" />
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                href="#"
                                className="text-dark fw-bold text-hover-primary fs-12"
                              >
                                {itm[i.name]}
                              </a>
                            </div>
                          </div>
                        )}
                        {i.type === "checkbox" && (
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value="1"
                            />
                          </div>
                        )}
                        {i.type === "progress" && (
                          <div className="d-flex flex-column w-100 me-2">
                            <div className="d-flex flex-stack mb-2">
                              <span className="text-muted me-2 fs-12 fw-bold">
                                {itm.Progress}%
                              </span>
                            </div>

                            <div className="progress h-6px w-100">
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{ width: `${itm.Progress}%` }}
                                aria-valuenow={itm.Progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        )}
                        {i.type === "button" && (
                          <div className="d-flex justify-content-end flex-shrink-0">
                            <a
                              href="#"
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                            >
                              <span className="svg-icon svg-icon-3">
                                <img src={Edit} alt="" />
                              </span>
                            </a>

                            <a
                              href="#"
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                            >
                              <span className="svg-icon svg-icon-3">
                                <img src={Delete} alt="" />
                              </span>
                            </a>
                          </div>
                        )}
                        {i.type === "date" && (
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <p className="mb-0   text-capitalize fw-bold text-hover-primary fs-12">
                                {moment(itm[i.name]).format("HH:MM:SS")}
                              </p>
                            </div>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>

          <div className="d-flex flex-stack flex-wrap pt-10 justify-content-center justify-content-md-center justify-content-lg-between">
            <div className="fs-14 fw-semibold text-gray-700">
              {/* Showing {currentPage} to {currentPage + limit} of {total} entries */}
            </div>

            {getVisiblePageNumbers().length > 0 && (
              <ul className="pagination">
                <li
                  className="page-item previous"
                  onClick={() => {
                    handlePrev();
                    // if (currentPage > 1) {
                    //   setCurrentPage(currentPage - 1);
                    setSkip(currentPage - 1);
                    // }
                  }}
                >
                  <div className="page-link cursor-pointer">
                    <img src={Previous} />
                  </div>
                </li>

                {/* Ellipsis on the left if not showing the first page */}
                {currentPage > Math.floor(maxVisibleButtons / 2) + 1 && (
                  <span>...</span>
                )}

                {/* Page buttons */}
                {getVisiblePageNumbers().map((pageNum, index) => (
                  <li
                    key={index}
                    className={
                      currentPage === pageNum
                        ? ` active page-item`
                        : " page-item"
                    }
                    onClick={() => {
                      handlePageClick(pageNum);
                    }}
                  >
                    <div className="page-link cursor-pointer">{pageNum}</div>
                  </li>

                  // <button
                  //   key={pageNum}
                  //   onClick={() => handlePageClick(pageNum)}
                  //   disabled={pageNum === currentPage}
                  // >
                  //   {pageNum}
                  // </button>
                ))}

                {/* Ellipsis on the right if not showing the last page */}
                {currentPage <
                  totalPages - Math.floor(maxVisibleButtons / 2) && (
                  <span>...</span>
                )}

                {/* {Array.from({ length: totalPages }, (_, i) => i + 1)?.map(
                (page, index) => (
                  <li
                    key={index}
                    className={
                      currentPage === page + 1
                        ? ` active page-item`
                        : " page-item"
                    }
                    onClick={() => {
                      handlePageClick(page + 1);
                    }}
                  >
                    <div className="page-link cursor-pointer">{page + 1}</div>
                  </li>
                )
              )} */}

                <li
                  className="page-item next"
                  onClick={() => {
                    handleNext();
                    // if (currentPage < total / limit) {
                    setSkip(currentPage + 1);
                    //   setCurrentPage(currentPage + 1);
                    // }
                  }}
                >
                  <div className="page-link cursor-pointer">
                    <img src={Next} style={{ transform: "rotate(180deg)" }} />
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Table.prototype = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  rowd: PropTypes.array,
  setRows: PropTypes.func,
  handleAction: PropTypes.func,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  handleClick: PropTypes.func,
};

export default Table;
