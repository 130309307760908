import React from "react";
import PropTypes from "prop-types";

const Button = ({name, handleSubmit, type, disabled}) => {
  return (
    <div className=" mb-2 w-100"> 
    {/* mx-1 mx-sm-1 mx-md-2 */}
      <button
        type="submit"
        id="kt_sign_in_submit"
        className={type === 'submit' ? 'btn-primary btn' : 'btn-light btn'}
        onClick={(e) => {
          e.preventDefault()
          handleSubmit(e);
        }}
        disabled={disabled}
      >
        <span className="indicator-label fs-16">{name}</span>

        {/* <span className="indicator-progress">
          {" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span> */}
      </button>
    </div>
  );
};

Button.prototype = {
  name: PropTypes.string,
  handleSubmit: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;
