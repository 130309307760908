import React, { useEffect } from "react";
import Dashboard from "../assets/images/svgs/dashboard.svg";
import Profile from "../assets/images/svgs/profile.svg";
import History from "../assets/images/svgs/history.svg";

export function useOutsideAlerter(ref, open, setOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const logoClass = event?.target?.className;
      if (logoClass && typeof logoClass !== "object") {
        if (logoClass?.indexOf("toggleIcon") !== -1) {
          setOpen(!open);
        } else if (ref?.current && !ref.current?.contains(event?.target)) {
          setOpen(open);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const setCookies = (key, value) => {
  // document.cookie = key + "=" + (value || "") + "; path=/";

  document.cookie = `${key}=${value}; path=/; max-age=604800; `;
};

export const getCookies = (key) => {
  const value = ("; " + document.cookie).split(`; ${key}=`).pop().split(";")[0];
  
  return value;
};

export const deleteCookies = (name) => {
  document.cookie =
    name +
    "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export let sidebarDrawerInit = true;

// export const handleSidebarDrawerInit = () => {
//   console.log(430,sidebarDrawerInit )
//   sidebarDrawerInit = !sidebarDrawerInit;
//   console.log(433,sidebarDrawerInit )
//   return sidebarDrawerInit;
// };

export const handleSidebarDrawerInit = () => {
  console.log(58, "handleSidebarDrawerInit")
  sidebarDrawerInit = !sidebarDrawerInit;
  return sidebarDrawerInit;
};

export const sideBarList = [
  {
    label: "Dashboard",
    url: "",
    img: <img src={Dashboard} />,
  },
  {
    label: "Profile",
    url: "profile",
    img: <img src={Profile} />,
  },
  {
    label: "Game History",
    url: "game-history",
    img: <img src={History} />,
  },
];

export function getRandomNumbersFromArray(arr, count) {
  const result = new Set();
  while (result.size < count) {
    const randomIndex = Math.floor(Math.random() * arr.length) + 1;
    if (randomIndex == arr.length) {
      result.add(arr[randomIndex - 1]);
    } else {
      result.add(arr[randomIndex]);
    }
  }

  return Array.from(result);
}
