import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import HumburgerMenu from "../pages/layout/sidebar";
import { userLogin } from "../redux/slices/userLoginSlice";

import { sidebarDrawerInit, getCookies, setCookies } from "../helper/utils";
import { USER_TOKEN } from "../helper/api";

const PrivateRoute = ({ path, element }) => {
  const dispatch = useDispatch();
  const auth = getCookies("token");
  const [menuOpen, setMenuOpen] = useState(sidebarDrawerInit ? false : true);
  const { innerWidth: width } = window;
  const drawerWidth = "201px";

  let forSmallScreen = menuOpen ? drawerWidth : "0px";
  let forLargeScrren = menuOpen ? drawerWidth : "0px"; // 50px
  let newMargin = width <= 768 ? forSmallScreen : forLargeScrren;

  if (window.top !== window.self) {
    console.log("This page is in an iframe. ooo");
    console.log("ooo Parent URL:", document.referrer, document.cookie);
  } else {
    console.log("ooo This page is not in an iframe.");
  }
  window.addEventListener(
    "message",
    (event) => {
      if (event.origin !== "http://localhost:3001/") return;

     
    },
    false
  );

  //  useEffect(() =>{
  const trustedOrigins = ["http://192.168.2.150:3001/"];

  function onMsg(msg) {
    console.log("msg", msg);
    if (!trustedOrigins.includes(msg.origin)) return;
    console.log(`Message from main window`, msg);
    window.parent.postMessage(
      JSON.stringify({
        message: "this should be delivered to main window",
      })
    );
  }

  window.addEventListener("onload", onMsg, false);
  //  }, [])

  useEffect(() => {
    getUserLogin();
  }, []);

  const getUserLogin = () => {
    const payload = {
      token: USER_TOKEN,
    };
    dispatch(userLogin(payload))
      .unwrap()
      .then((res) => {
        setCookies("token", res.data.token);
        setCookies("user_id", res.data._id);
        setCookies("wallet_balance", res.data.wallet_balance);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  };

  return auth ? (
    <div className="wrapper">
      
      <div className={"page-wrap"}>
        <HumburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />

        <div
          style={{
            marginLeft: newMargin,
            transition: "all 0.5s ease",
          }}
        >
          {element}
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
